import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styled, {createGlobalStyle} from "styled-components"
import { graphql } from 'gatsby';
import Nav from '../components/Nav'
import TuoteSivu from '../components/TuoteSivu'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.white ? '#FFFFFF' : '#F0F0F0')};
  }
`
const Kehys = styled.div`
padding-top: 40px;
padding-bottom: 40px;
padding-left: 40px;
padding-right: 40px;
height: auto;
`
const KehysM = styled.div`
padding-top: 40px;
padding-bottom: 40px;
padding-left: 20px;
padding-right: 20px;
height: auto;
`

const SisempiKehys = styled.div`
height: auto;
padding-bottom: 40px;
`
const Otsikko = styled.h1`
padding-top: 0px;
margin: 0px;
font-weight: bold;
font-size: 16px;
`
const EsittelyTeksti = styled.h1`
margin:0;
padding-top: 14px;
padding-bottom: 40px;
font-size: 14px;
line-height: 2px;
`
const Desktop = styled.div`
width: 1260px;
background:white;
height: 100;
`
const Responsive = styled.div`
display:grid;
grid-template-columns: 1fr 1260px 1fr;
height: 100vh;
margin:0;
padding:0;
`
const BackGround = styled.div`
background-color: #F0F0F0;
z-index: -1000;
height: 100%;
`
const Spacer = styled.div`
height: 64px;
`
const Esittely = ({ teksti, nimi }) => {
  return (
    <SisempiKehys>
      <Otsikko>{nimi}</Otsikko>
      <p>{teksti}</p>
    </SisempiKehys>
  )
}

function MobileIndex({ tuotteet, teksti, nimi }) {
  return (
    <Fragment>
      <GlobalStyle white />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Metaka 240 -koukkulavavaunu</title>
      </Helmet>
      <Nav />
      <Spacer />
      <Slider>
            <StaticImage
              alt="Metaka 240 -koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/1_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240 -koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/2_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240 -koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/3_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240 -koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/4_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240 -koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/5_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240 -koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/6_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240 -koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/7_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240 -koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/8_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240 -koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/9_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240 -koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/10_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240 -koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/11_web.jpg"}
            />
          </Slider>
      <KehysM>
        <TuoteSivu tuotteet={tuotteet} />
      </KehysM>
    </Fragment>
  )
}

function DesktopIndex({ tuotteet, teksti, nimi}) {
  let kuvat = tuotteet[0].tuotteen_kuvat;
  return (
    <Fragment>
      <GlobalStyle />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Metaka 240-koukkulavavaunu</title>
      </Helmet>
      <Responsive>
        <BackGround />
        <Desktop>
          <Nav />
          <Spacer />
          <Slider>
            <StaticImage
              alt="Metaka 240-koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/1_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240-koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/2_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240-koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/3_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240-koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/4_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240-koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/5_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240-koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/6_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240-koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/7_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240-koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/8_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240-koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/9_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240-koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/10_web.jpg"}
            />
            <StaticImage
              alt="Metaka 240-koukkulavavaunu"
              src={"../assets/tuotekuvat/metaka240/11_web.jpg"}
            />
          </Slider>
          <Kehys>
            <TuoteSivu desktop tuotteet={tuotteet} />
          </Kehys>
        </Desktop>
        <BackGround />
      </Responsive>
    </Fragment>
  )
}

function Metaka240({ data }) {
  const breakpoints = useBreakpoint();
  const tuote = data.allSanityTuotteet.nodes;
  const nimi = data.allSanityTuotteet.nodes[0].tuotteen_nimi;
  const teksti = data.allSanityTuotteet.nodes[0].tuotteen_esittelyteksti;
  console.log(tuote)
  //tuote[0].tuotteen_kuvat = ["../assets/tuotekuvat/1_web.jpg","../assets/tuotekuvat/2_web.jpg","../assets/tuotekuvat/3_web.jpg"]
  console.log(tuote[0].tuotteen_kuvat)
  //{breakpoints.md ? <MobileIndex tuotteet={tuotteet} teksti={teksti} /> : <DesktopIndex tuotteet={tuote} teksti={teksti} />}
  return (
    <div>
    {breakpoints.md ? <MobileIndex tuotteet={tuote} teksti={teksti} /> : <DesktopIndex tuotteet={tuote} teksti={teksti} />}
    </div>
  )
}

export default Metaka240

export const query = graphql`
query Metaka240 {
  allSanityTuotteet(filter: {tuotteen_nimi: {eq: "Metaka 240"}}) {
    nodes {
      tuotteen_nimi
      telipaino
      rungon_pituus
      rungon_korkeus_maasta
      rengaskoko
      omapaino
      lisavarusteet
      lavan_pituudet
      korkeus_lavan_pohjaan_maasta
      kokonaispaino
      kippauskulma
      kantavuus
      akselien_maara
      aisapaino
      tuotteen_esittelyteksti
      tuotteen_lyhyt_esittely
      tuotteen_kuvat {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
  }
}
`




